@import '/src/styles/_theming.scss';

@include mat-core();

body {
  &.light-theme {
    @include apply-mat-theme($light-theme);
  }

  .mat-error {
    @include error();
  }

  .mat-input-element {
    @include no-wrap();
  }

  b {
    font-weight: bold;
  }

  .mat-snack-bar-container.notification-base {
    padding: 0;
    border-radius: 25px;
    background-color: transparent;
  }
}
