/*#region Base Colors*/

$gray-1: rgba(0, 0, 0, 0.12);
$gray-2: rgba(0, 0, 0, 0.4);
$gray-3: #38424c;
$gray-4: #f6f6f6;
$gray-5: #e6e6e6;
$gray-6: #f4f4f4;
$gray-7: #f2f2f2;
$gray-8: rgba(0, 0, 0, 0.3);

$white-1: #fafafa;

$blue-1: #0f0fdb;
$blue-2: #0047ab;
$blue-3: #6495ed;
$blue-4: #2196f3;

$black-1: black;

$red-2: #db202f;
$red-3: #cd324c;

$pink-1: #ff1493;

$green-1: #00b300;

/*#endregion Base Colors*/

$background-color: $white-1;
$border-color: $gray-1;

$link-color: $gray-2;
$link-visited-color: $blue-1;
$link-active-color: $blue-4;
$link-disabled-color: $gray-8;

$table-nth-background-color: $gray-6;

$item-hover-color: $gray-7;
$item-menu-hover-color: $gray-5;

$success-color: $green-1;

$failure-color: $red-2;
