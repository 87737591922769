@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '/src/styles/_colors.scss';
@import '/src/styles/_media.scss';
@import '/src/styles/_forms.scss';

$light-theme-primary: mat-palette($mat-blue, 500);
$light-theme-accent: mat-palette($mat-teal);
$light-theme: mat-light-theme($light-theme-primary, $light-theme-accent);

$list-item-padding: 15px 0;

$tab-menu-font-size: 18px;

@mixin apply-mat-theme($theme) {
  @include angular-material-theme($theme);
}

@mixin no-select {
  webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@mixin no-wrap {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

@mixin title {
  width: 100%;
  font-weight: bold;
}

@mixin empty-list-label {
  margin-top: 30px;
}

@mixin mat-button {
  border-radius: $border-radius;
  padding: 5px 0;
}

@mixin submit-button {
  @include mat-button();
  min-width: 150px;
}

@mixin mat-icon-size($icon-size) {
  &.mat-icon,
  .mat-icon {
    font-size: $icon-size;
    width: $icon-size;
    height: $icon-size;
    line-height: $icon-size;
  }
}

@mixin mat-icon-button-size($background-size, $icon-size) {
  @include mat-icon-size($icon-size);
  width: auto;
  height: $background-size;
  line-height: $background-size;

  &.mat-fab,
  &.mat-mini-fab,
  &.mat-icon-button {
    width: $background-size;
  }

  .mat-button-wrapper {
    line-height: $background-size;
  }
}

@mixin circle($size) {
  height: $size;
  width: $size;
  border-radius: 50%;
  display: inline-block;
}

@mixin circle-icon($background-size) {
  @include circle($background-size);

  place-content: center;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
}

// item with an icon on the right
@mixin mat-list-icon-item {
  $icon-size: 44px;
  $content-btn-padding: 15px;

  .mat-list-item,
  &.mat-list-item {
    @include default-border();
    display: block;
    padding: $list-item-padding;
    margin-bottom: 15px;
    transition: background-color 0.2s ease-in-out;
    font-size: 16px;
    height: 100%;
    padding: 0;

    &:focus {
      outline: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: $item-hover-color;
    }

    .mat-list-item-content {
      padding: 0;
    }

    .item-content {
      min-height: calc(44px + (#{$content-btn-padding} * 2));
      overflow-x: hidden;
      padding: $content-btn-padding 16px;
      height: inherit;
    }

    .item-icon {
      @include circle-icon($icon-size);
      min-width: $icon-size;
      background-color: $blue-2;

      .mat-icon {
        color: white;
      }
    }

    .nav-icon-wrapper {
      padding: 0 16px;

      .nav-icon {
        color: $blue-3;
      }
    }

    .item-icon,
    .nav-icon {
      @include no-select();
    }
  }
}

// item with menu on the right
@mixin mat-list-menu-item {
  @include mat-list-icon-item();
  .mat-list-item,
  &.mat-list-item {
    .item-menu-trigger {
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background: $item-menu-hover-color;
      }
    }
  }
}

// item to be used as ACTION (e.g. Add)
@mixin mat-list-action-icon-item {
  @include mat-list-icon-item();
  .mat-list-item,
  &.mat-list-item {
    background-color: $item-hover-color;
    border: none;

    .item-icon {
      background-color: $pink-1;
    }

    .nav-icon.mat-icon,
    .nav-icon .mat-icon {
      color: $pink-1;
    }
  }
}

@mixin link {
  text-decoration: none;
  color: $link-color;
  outline: 0;
  cursor: pointer;

  &:visited {
    color: $link-visited-color;
  }

  &:not(.active) {
    cursor: pointer;
  }

  &.active {
    color: mat-color($light-theme-primary, default);
    border: none;
    cursor: default;
    pointer-events: none;
  }

  &.disabled {
    pointer-events: none;
    color: $link-disabled-color;
  }
}

@mixin error {
  font-size: 12;

  .mat-error,
  &.mat-error {
    font-size: 12px;
  }
}

@mixin column-page {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}
